<!-- Initialize a CompareBar element but with pre-selected options -->
<template>
  <v-container fill-height>
    <!-- 4 buttons with hover effect. 1 button will load some particular options into the compare bar -->
    <v-row justify="center" align="center">
      <v-hover v-slot="{ hover }">
        <button
          :class="
            hover && !isMobile && active !== 'canopy' ? 'hover-effect' : ''
          "
        >
          <v-icon
            v-if="!isMobile && active !== 'canopy'"
            class="ma-5 compare-bar-opt-btn compare-bar-opt-btn-active"
            size="64"
            @click="toggleOptions('perHab')"
            >$vuetify.icon.canopy</v-icon
          >
          <v-icon
            v-if="!isMobile && active === 'canopy'"
            class="ma-5 compare-bar-opt-btn compare-bar-opt-btn-active"
            size="64"
            @click="toggleOptions('perHab')"
            >$vuetify.icon.canopy_active</v-icon
          >
          <v-icon
            v-if="isMobile && active !== 'canopy'"
            class="ma-5 compare-bar-opt-btn compare-bar-opt-btn-active"
            size="35px"
            @click="toggleOptions('perHab')"
            >$vuetify.icon.canopy</v-icon
          >
          <v-icon
            v-if="isMobile && active === 'canopy'"
            class="ma-5 compare-bar-opt-btn compare-bar-opt-btn-active"
            size="35px"
            @click="toggleOptions('perHab')"
            >$vuetify.icon.canopy_active</v-icon
          >
        </button>
      </v-hover>
      <v-hover v-slot="{ hover }">
        <button
          :class="hover && !isMobile && active !== 'walk' ? 'hover-effect' : ''"
        >
          <v-icon
            v-if="!isMobile && active !== 'walk'"
            id="walk"
            class="ma-5 compare-bar-opt-btn"
            size="64"
            @click="toggleOptions('walk')"
            >$vuetify.icon.walk</v-icon
          >
          <v-icon
            v-if="!isMobile && active === 'walk'"
            class="ma-5 compare-bar-opt-btn"
            size="64"
            @click="toggleOptions('walk')"
            >$vuetify.icon.walk_active</v-icon
          >
          <v-icon
            v-if="isMobile && active !== 'walk'"
            id="walk"
            class="ma-5 compare-bar-opt-btn"
            size="35px"
            @click="toggleOptions('walk')"
            >$vuetify.icon.walk</v-icon
          >
          <v-icon
            v-if="isMobile && active === 'walk'"
            class="ma-5 compare-bar-opt-btn"
            size="35px"
            @click="toggleOptions('walk')"
            >$vuetify.icon.walk_active</v-icon
          >
        </button>
      </v-hover>
      <v-hover v-slot="{ hover }">
        <button
          :class="
            hover && !isMobile && active !== 'connectivity'
              ? 'hover-effect'
              : ''
          "
        >
          <v-icon
            v-if="!isMobile && active !== 'connectivity'"
            id="space"
            class="ma-5 compare-bar-opt-btn"
            size="64"
            @click="toggleOptions('space')"
            >$vuetify.icon.connectivity</v-icon
          >
          <v-icon
            v-if="!isMobile && active === 'connectivity'"
            class="ma-5 compare-bar-opt-btn"
            size="64"
            @click="toggleOptions('space')"
            >$vuetify.icon.connectivity_active</v-icon
          >
          <v-icon
            v-if="isMobile && active !== 'connectivity'"
            id="space"
            class="ma-5 compare-bar-opt-btn"
            size="35px"
            @click="toggleOptions('space')"
            >$vuetify.icon.connectivity</v-icon
          >
          <v-icon
            v-if="isMobile && active === 'connectivity'"
            class="ma-5 compare-bar-opt-btn"
            size="35px"
            @click="toggleOptions('space')"
            >$vuetify.icon.connectivity_active</v-icon
          >
        </button>
      </v-hover>
      <v-hover v-slot="{ hover }">
        <button
          :class="hover && !isMobile && active !== 'tree' ? 'hover-effect' : ''"
        >
          <v-icon
            v-if="!isMobile && active !== 'tree'"
            id="canopy"
            class="ma-5 compare-bar-opt-btn"
            size="64"
            @click="toggleOptions('tree')"
            >$vuetify.icon.tree</v-icon
          >
          <v-icon
            v-if="!isMobile && active === 'tree'"
            class="ma-5 compare-bar-opt-btn"
            size="64"
            @click="toggleOptions('tree')"
            >$vuetify.icon.tree_active</v-icon
          >
          <v-icon
            v-if="isMobile && active !== 'tree'"
            id="canopy"
            class="ma-5 compare-bar-opt-btn"
            size="35px"
            @click="toggleOptions('tree')"
            >$vuetify.icon.tree</v-icon
          >
          <v-icon
            v-if="isMobile && active === 'tree'"
            class="ma-5 compare-bar-opt-btn"
            size="35px"
            @click="toggleOptions('tree')"
            >$vuetify.icon.tree_active</v-icon
          >
        </button>
      </v-hover>
    </v-row>
    <!-- Initialize comparebar with selected properties -->
    <v-row class="mt-3 pt-3">
      <CompareBar
        v-if="init"
        :walk="walk"
        :progress-value="progressValue"
        :unit-global="unitGlobal"
        :title="label"
        :value1="value1"
        :value2="value2"
        :unit1="unit1"
        :unit2="unit2"
        :is-mobile="isMobile"
      />
    </v-row>
  </v-container>
</template>

<script>
import CompareBar from "@/components/Compare/CompareBar";

export default {
  name: "CompareBarOpt",
  components: {
    CompareBar,
  },
  props: {
    city1: {
      type: Array,
      required: true,
    },
    city2: {
      type: Array,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      // Allow this component to know what the user chose
      active: "canopy",
      progressValue: undefined,
      // First unit
      unit1: "m²",
      // Second unit
      unit2: "m²",
      // Default unit
      unitGlobal: "m²",
      // First value
      value1: undefined,
      // Second value
      value2: undefined,
      // Particular options if walk is true
      walk: false,
    };
  },
  computed: {
    // Check if there is values to load a CompareBar
    init() {
      return this.value1 !== undefined && this.value2 !== undefined;
    },
    // Load particular label according to the active attribute
    label() {
      var result;
      if (this.active === "canopy") {
        result = this.label1;
      } else if (this.active === "walk") {
        result = this.label2;
      } else if (this.active === "connectivity") {
        result = this.label3;
      } else {
        result = this.label4;
      }
      return result;
    },
    // First label
    label1() {
      return this.$t("compare.focus.compare-bar-opt.opt1");
    },
    // Second label
    label2() {
      return this.$t("compare.focus.compare-bar-opt.opt2");
    },
    // Third label
    label3() {
      return this.$t("compare.focus.compare-bar-opt.opt3");
    },
    // Forth label
    label4() {
      return this.$t("compare.focus.compare-bar-opt.opt4");
    },
  },
  // Load default values
  mounted() {
    this.value1 = this.city1[1].SurfTAHab;
    this.value2 = this.city2[1].SurfTAHab;
    if (this.value1 > this.value2) {
      this.progressValue = Math.round(this.value1 - this.value2);
    } else {
      this.progressValue = Math.round(this.value2 - this.value2);
    }
  },
  methods: {
    // toggle particular properties
    toggleOptions(value) {
      if (value === "perHab") {
        this.label = this.label1;
        this.value1 = this.city1[1].SurfTAHab;
        this.value2 = this.city2[1].SurfTAHab;
        this.unit1 = "m²";
        this.unit2 = "m²";
        this.unitGlobal = "m²";
        if (this.value1 > this.value2) {
          this.progressValue = Math.round(this.value1 - this.value2);
        } else {
          this.progressValue = Math.round(this.value2 - this.value1);
        }
        this.walk = false;
        this.active = "canopy";
      } else if (value === "walk") {
        this.label = this.label2;
        this.value1 = this.city1[1].AccesParcMoy;
        this.value2 = this.city2[1].AccesParcMoy;
        this.unit1 = "min";
        this.unit2 = "min";
        this.unitGlobal = "min";
        if (this.value1 > this.value2) {
          this.progressValue = Math.round(this.value1 - this.value2);
        } else {
          this.progressValue = Math.round(this.value2 - this.value1);
        }
        this.walk = true;
        this.active = "walk";
      } else if (value === "space") {
        this.label = this.label3;
        this.value1 = this.city1[1].VegConnect;
        this.value2 = this.city2[1].VegConnect;
        this.unit1 = "";
        this.unit2 = "";
        this.unitGlobal = "pts";
        if (this.value1 > this.value2) {
          this.progressValue = Math.round(this.value1 - this.value2);
        } else {
          this.progressValue = Math.round(this.value2 - this.value1);
        }
        this.walk = false;
        this.active = "connectivity";
      } else {
        this.label = this.label4;
        this.value1 = this.city1[1].PercTAVeg;
        this.value2 = this.city2[1].PercTAVeg;
        this.unit1 = "%";
        this.unit2 = "%";
        this.unitGlobal = "pts";
        if (this.value1 > this.value2) {
          this.progressValue = Math.round(this.value1 - this.value2);
        } else {
          this.progressValue = Math.round(this.value2 - this.value1);
        }
        this.walk = false;
        this.active = "tree";
      }
    },
  },
};
</script>

<style scoped>
.compare-bar-opt-btn {
  cursor: pointer;
  color: rgba(61, 106, 188, 255);
}

.compare-bar-opt-btn-active {
  cursor: pointer;
  color: #91bd81;
}

.hover-effect {
  background-color: rgba(105, 185, 112, 0.1);
  border-radius: 50px;
}
</style>

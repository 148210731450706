<!-- Global component going on the left or the right whether city1 or city2 is better on a point -->
<template>
  <v-container fill-height :style="isMobile ? '' : 'max-width: 70%'">
    <v-row class="compare-bar-row" justify="center" align="center">
      <v-col>
        <p class="right-value">{{ Math.round(value1) }} {{ unit1 }}</p>
      </v-col>
      <v-col cols="5">
        <p class="legend">{{ title }}</p>
      </v-col>
      <v-col>
        <p class="left-value">{{ Math.round(value2) }} {{ unit2 }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-progress-linear
        :value="100"
        color="#94c081"
        height="15"
        class="ma-3"
        style="border-radius: 15px"
      ></v-progress-linear>
      <div ref="compareBarLoadingBubble" class="compare-bar-loading-bubble">
        <div class="compare-bar-loading-bubble-percentage">
          <span v-if="!walk" style="font-size: initial">+</span
          ><span v-else style="font-size: initial">-</span>{{ progressValue
          }}<span style="font-size: initial">{{ unitGlobal }}</span>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CompareBar",
  props: {
    // The value
    progressValue: {
      type: Number,
      required: false,
      default: undefined,
    },
    // Main unit
    unitGlobal: {
      type: String,
      default: "%",
    },
    // city1 value
    value1: {
      type: Number,
      default: 789,
    },
    // city1 unit
    unit1: {
      type: String,
      default: "km²",
    },
    // city2 value
    value2: {
      type: Number,
      default: 632,
    },
    // city2 unit
    unit2: {
      type: String,
      default: "km²",
    },
    // CompareBar title
    title: {
      type: String,
      default: "Part de surface verte",
    },
    // display particular unit if walk mode is activated
    walk: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  // Always check if value 1 or value 2 is updated
  watch: {
    value1() {
      this.compute();
      this.manageBubblePosition();
    },
    value2() {
      this.compute();
      this.manageBubblePosition();
    },
  },
  // First compute then place bubble
  mounted() {
    this.compute();
    this.manageBubblePosition();
  },
  // On update, re-compute bubble position
  updated() {
    this.manageBubblePosition();
  },
  methods: {
    compute() {
      if (this.progressValue === undefined) {
        if (this.value1 >= this.value2) {
          this.progressValue = Math.round(
            ((this.value1 - this.value2) / this.value2) * 100
          );
        } else {
          this.progressValue = Math.round(
            ((this.value2 - this.value1) / this.value1) * 100
          );
        }
      }
    },
    // Adapt bubble position depending on greenness value
    manageBubblePosition() {
      if (this.walk) {
        if (this.value1 < this.value2) {
          this.$refs.compareBarLoadingBubble.setAttribute(
            "style",
            "left : calc(25% - 52px);"
          );
          this.$refs.compareBarLoadingBubble.classList.add(
            "compare-bar-loading-bubble-high-value"
          );
        } else if (this.value1 === this.value2) {
          this.$refs.compareBarLoadingBubble.setAttribute(
            "style",
            "left : calc(50% - 52px);"
          );
          this.$refs.compareBarLoadingBubble.classList.add(
            "compare-bar-loading-bubble-high-value"
          );
        } else {
          this.$refs.compareBarLoadingBubble.setAttribute(
            "style",
            "left : calc(75% - 52px);"
          );
          this.$refs.compareBarLoadingBubble.classList.add(
            "comparebar-loading-bubble-high-value"
          );
        }
      } else {
        if (this.value1 > this.value2) {
          this.$refs.compareBarLoadingBubble.setAttribute(
            "style",
            "left : calc(25% - 52px);"
          );
          this.$refs.compareBarLoadingBubble.classList.add(
            "compare-bar-loading-bubble-high-value"
          );
        } else if (this.value1 === this.value2) {
          this.$refs.compareBarLoadingBubble.setAttribute(
            "style",
            "left : calc(50% - 52px);"
          );
          this.$refs.compareBarLoadingBubble.classList.add(
            "compare-bar-loading-bubble-high-value"
          );
        } else {
          this.$refs.compareBarLoadingBubble.setAttribute(
            "style",
            "left : calc(75% - 52px);"
          );
          this.$refs.compareBarLoadingBubble.classList.add(
            "comparebar-loading-bubble-high-value"
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.right-value {
  color: white;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.legend {
  color: white;
  font-weight: bold;
  font-size: 1.2vw;
  text-align: center;
}

.left-value {
  color: white;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.compare-bar-row {
  min-height: 99px;
}

.compare-bar-loading-bubble {
  position: relative;
  width: 100px;
  height: 48px;
  padding: 0px;
  background: #f4f3f1;
  z-index: 1;
  bottom: 0px;
  transition: all 0.3s ease-in-out;
  background-color: #62c0fe;
}

.compare-bar-loading-bubble::before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0px 10px 10px;
  border-color: #62c0fe transparent;
  display: block;
  width: 0;
  z-index: 2;
  bottom: 46px;
  left: calc(-10px + 50%);
}

.compare-bar-loading-bubble-high-value {
  position: relative;
  width: 85px;
  height: 48px;
  padding: 0px;
  background: #61befc;
  border-radius: 0px;
  border: #61befc solid 2px;
  z-index: 1;
  bottom: 0px;
  transition: all 0.3s ease-in-out;
}

.compare-bar-loading-bubble-high-value::before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0px 10px 10px;
  border-color: #62c0fe transparent;
  display: block;
  width: 0;
  z-index: 2;
  bottom: 46px;
  left: calc(-10px + 50%);
}

.compare-bar-loading-bubble-percentage {
  color: white;
  z-index: 3;
  font-size: xx-large;
  font-weight: bold;
  position: relative;
  left: 10%;
}

.compare-bar-loading-bubble-text {
  position: absolute;
  top: -8px;
  z-index: 3;
  line-height: 90%;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 800px) {
  .right-value {
    font-size: 18px;
  }

  .left-value {
    font-size: 18px;
  }

  .legend {
    font-size: 14px;
  }
}
</style>

<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <p class="compare-graph-title">
        {{ $t("compare.focus.graph.title") }}
      </p>
    </v-row>
    <v-row justify="center" align="center">
      <p class="compare-graph-subtitle">
        {{ $t("compare.focus.graph.subtitle") }}
      </p>
    </v-row>
    <v-row no-gutters class="mt-4" justify="center" align="center">
      <v-col :cols="isMobile ? '11' : '7'">
        <canvas ref="ecartChart" class="compare-chart-canvas"></canvas>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <button
        id="compare-graph-legend-btn-city"
        class="mr-15 compare-graph-legend-btn"
        @click="toggleEntry('compare-graph-legend-btn-city')"
      >
        <v-icon class="mr-3 pb-1" :color="legendColor[0]">mdi-circle</v-icon>
        <span>{{ city1[0] }}</span>
      </button>
      <button
        id="compare-graph-legend-btn-city2"
        class="compare-graph-legend-btn"
        @click="toggleEntry('compare-graph-legend-btn-city2')"
      >
        <v-icon class="mr-3 pb-1" :color="legendColor[1]">mdi-circle</v-icon>
        <span>{{ city2[0] }}</span>
      </button>
    </v-row>
    <v-row>
      <div class="year-btns">
        <a
          id="btn-long-range"
          class="
            compare-div-stats-redirect-btn compare-div-stats-redirect-btn-active
          "
          @click="toggleRange($event)"
        >
          1981-2020
        </a>
      </div>
      <div style="margin: auto">
        <a
          id="btn-short-range"
          class="compare-div-stats-redirect-btn"
          @click="toggleRange($event)"
        >
          2011-2020
        </a>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import Chart from "chart.js/auto";
import axios from "axios";

export default {
  name: "CompareGraph",
  props: {
    city1: {
      required: true,
      type: Array,
    },
    city2: {
      required: true,
      type: Array,
    },
    isMobile: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeShortChart: false,
      data1: [],
      data2: [],
      legendColor: ["#61c0ff", "#12368c"],
      ecartChart: null,
      shortData1: [],
      shortData2: [],
    };
  },
  async mounted() {
    await this.loadMonthlyData();
    await this.loadMonthlyData2();
    await this.loadEcart();
    await this.loadEcart2();
    this.addEcartChart();
  },
  methods: {
    async loadEcart() {
      let ecartResponse;
      try {
        ecartResponse = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL + this.city1[0] + "/ecart"
        );
        ecartResponse = ecartResponse.data;
        if (!this.city1.weather) {
          this.city1.weather = {};
        }
        this.city1.weather.ecartYearly = ecartResponse;
      } catch (error) {
        console.error(error);
      }
    },
    async loadEcart2() {
      let ecartResponse;
      try {
        ecartResponse = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL + this.city2[0] + "/ecart"
        );
        ecartResponse = ecartResponse.data;
        if (!this.city2.weather) {
          this.city2.weather = {};
        }
        this.city2.weather.ecartYearly = ecartResponse;
      } catch (error) {
        console.error(error);
      }
    },
    async loadMonthlyData() {
      let nPMonthlyResponse;
      try {
        // Get data from local Nasa Power api for historical weather information for charts
        nPMonthlyResponse = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL + this.city1[0] + "/monthly"
        );
        nPMonthlyResponse = nPMonthlyResponse.data;
        if (!this.city1.weather) {
          this.city1.weather = {};
        }
        this.city1.weather.monthly = nPMonthlyResponse;
      } catch (error) {
        console.error(error);
      }
    },
    async loadMonthlyData2() {
      let nPMonthlyResponse;
      try {
        // Get data from local Nasa Power api for historical weather information for charts
        nPMonthlyResponse = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL + this.city2[0] + "/monthly"
        );
        nPMonthlyResponse = nPMonthlyResponse.data;
        if (!this.city2.weather) {
          this.city2.weather = {};
        }
        this.city2.weather.monthly = nPMonthlyResponse;
      } catch (error) {
        console.error(error);
      }
    },
    addEcartChart() {
      if (this.ecartChart) {
        this.ecartChart.destroy();
      }
      Chart.defaults.font.family = "BR Candor";
      var self = this;
      var chartLabel = [];
      var key = 0;
      for (key in this.city1.weather.ecartYearly.YEAR) {
        chartLabel.push(this.city1.weather.ecartYearly.YEAR[key]);
      }
      this.data1 = [];
      for (key in this.city1.weather.ecartYearly.ecart_city) {
        this.data1.push(this.city1.weather.ecartYearly.ecart_city[key]);
      }
      this.data2 = [];
      for (key in this.city2.weather.ecartYearly.ecart_city) {
        this.data2.push(this.city2.weather.ecartYearly.ecart_city[key]);
      }
      for (var i = this.data1.length - 11; i < this.data1.length; i++) {
        this.shortData1.push(this.data1[i]);
        this.shortData2.push(this.data2[i]);
      }
      this.ecartChart = new Chart(this.$refs.ecartChart, {
        type: "line",
        data: {
          labels: chartLabel,
          datasets: [
            {
              label: self.city1[0],
              borderColor: "#61c0ff",
              backgroundColor: "#61c0ff",
              data: self.data1,
            },
            {
              label: self.city2[0],
              borderColor: "#12368c",
              backgroundColor: "#12368c",
              data: self.data2,
            },
            {
              label: self.city1[0],
              borderColor: "#61c0ff",
              backgroundColor: "#61c0ff",
              data: self.shortData1,
              hidden: true,
            },
            {
              label: self.city2[0],
              borderColor: "#12368c",
              backgroundColor: "#12368c",
              data: self.shortData2,
              hidden: true,
            },
          ],
        },
        options: {
          elements: {
            line: {
              borderWidth: 2.8,
              tension: 0.25,
              cubicInterpolationMonde: "monotone",
              borderCapStyle: "round",
              borderJoinStyle: "round",
            },
            point: {
              pointRadius: 0,
              hitRadius: 40,
              hoverRadius: 6,
              hoverBorderWidth: 2,
            },
          },
          plugins: {
            legend: {
              reverse: true,
              display: false,
              position: "bottom",
              labels: {
                font: {
                  size: 14,
                  family: "BR Candor",
                },
              },
            },
            tooltip: {
              enabled: true,
              mode: "index",
              position: "nearest",
              backgroundColor: "rgb(255,255,255,0.9)",
              titleColor: "rgb(0,0,0)",
              bodyColor: "rgb(0,0,0)",
              bodySpacing: 4,
              caretPadding: 5,
              borderWidth: 0.7,
              borderColor: "rgba(217, 217, 217)",
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              grid: {
                display: true,
                tickLength: 0,
                color: function (context) {
                  if (context.tick.value === 0) {
                    return "#000000";
                  } else {
                    return "#e6e6e6";
                  }
                },
              },
              ticks: {
                padding: 7,
              },
            },
          },
        },
      });
    },
    toggleEntry(legend) {
      var element = document.getElementById(legend);
      var scale = legend.slice(-4);

      // Update Button style
      if (element.classList.contains("compare-graph-legend-btn-inactive")) {
        element.classList.remove("compare-graph-legend-btn-inactive");
        // Display or Hide data
        if (scale === "city") {
          if (!this.activeShortChart) {
            this.ecartChart.setDatasetVisibility(0, true);
          } else {
            this.ecartChart.setDatasetVisibility(2, true);
          }
        } else {
          if (!this.activeShortChart) {
            this.ecartChart.setDatasetVisibility(1, true);
          } else {
            this.ecartChart.setDatasetVisibility(3, true);
          }
        }
      } else {
        element.classList.add("compare-graph-legend-btn-inactive");
        // Display or Hide data
        if (scale === "city") {
          this.ecartChart.setDatasetVisibility(0, false);
          this.ecartChart.setDatasetVisibility(2, false);
        } else {
          this.ecartChart.setDatasetVisibility(1, false);
          this.ecartChart.setDatasetVisibility(3, false);
        }
      }
      this.ecartChart.update();
    },
    toggleRange(event) {
      var chartLabel;
      if (
        document
          .getElementById("compare-graph-legend-btn-city")
          .classList.contains("compare-graph-legend-btn-inactive")
      ) {
        document
          .getElementById("compare-graph-legend-btn-city")
          .classList.remove("compare-graph-legend-btn-inactive");
      }
      if (
        document
          .getElementById("compare-graph-legend-btn-city2")
          .classList.contains("compare-graph-legend-btn-inactive")
      ) {
        document
          .getElementById("compare-graph-legend-btn-city2")
          .classList.remove("compare-graph-legend-btn-inactive");
      }
      if (event.target.id === "btn-long-range") {
        this.activeShortChart = false;
        this.ecartChart.setDatasetVisibility(0, true);
        this.ecartChart.setDatasetVisibility(1, true);
        this.ecartChart.setDatasetVisibility(2, false);
        this.ecartChart.setDatasetVisibility(3, false);
        chartLabel = [];
        for (var key in this.city1.weather.ecartYearly.YEAR) {
          chartLabel.push(this.city1.weather.ecartYearly.YEAR[key]);
        }
        this.ecartChart.data.labels = chartLabel;
      } else {
        this.activeShortChart = true;
        this.ecartChart.setDatasetVisibility(0, false);
        this.ecartChart.setDatasetVisibility(1, false);
        this.ecartChart.setDatasetVisibility(2, true);
        this.ecartChart.setDatasetVisibility(3, true);
        chartLabel = [];
        for (var i = 0; i < 11; i++) {
          chartLabel.push(
            this.city1.weather.ecartYearly.YEAR[
              Object.keys(this.city1.weather.ecartYearly.YEAR).length - 1 - i
            ]
          );
        }
        this.ecartChart.data.labels = chartLabel.reverse();
      }
      this.ecartChart.update();

      // Change the style of the selected button
      let toggleBtns = document.getElementsByClassName(
        "compare-div-stats-redirect-btn-active"
      );
      for (let btn of toggleBtns) {
        btn.classList.remove("compare-div-stats-redirect-btn-active");
      }
      event.target.classList.toggle("compare-div-stats-redirect-btn-active");
    },
  },
};
</script>

<style scoped>
.compare-graph-title {
  font-size: large;
}

.compare-graph-subtitle {
  color: #aabcc6;
}

.compare-chart-canvas {
  min-height: 15vw;
  background-color: rgba(244, 244, 244, 255);
  padding: 1% 3% 1% 1%;
  border-radius: 15px;
}

.compare-graph-legend-btn-inactive {
  opacity: 0.25;
}

.compare-div-stats-redirect-btn {
  position: relative;
  top: 24px;
  right: 28px;
  color: #113689;
  text-decoration: none;
  padding: 15px 20px 15px 20px;
  border: 2px solid #ffda36;
  border-radius: 30px;
}

.compare-div-stats-redirect-btn-active {
  background-color: #ffda36;
}

.year-btns {
  margin: auto;
}

@media screen and (min-width: 800px) {
  .compare-graph-legend-btn:hover {
    opacity: 0.25;
  }

  .compare-graph-legend-btn-inactive:hover {
    opacity: 0.75;
  }

  .compare-div-stats-redirect-btn:hover {
    background-color: #ffda36;
  }

  .compare-div-stats-redirect-btn-active:hover {
    position: relative;
    top: 24px;
    right: 28px;
    color: #113689;
    text-decoration: none;
    padding: 15px 20px 15px 20px;
    border: 2px solid #ffda36;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0);
  }
}

@media screen and (max-width: 800px) {
  .compare-graph-title {
    font-size: 18px;
  }

  .compare-graph-subtitle {
    font-size: 14px;
  }

  .compare-chart-canvas {
    min-height: 250px;
    max-height: 300px;
  }

  .year-btns {
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .compare-div-stats-redirect-btn {
    top: inherit;
    right: inherit;
  }

  .compare-div-stats-redirect-btn:hover {
    top: inherit;
    right: inherit;
  }
}
</style>

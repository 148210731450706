<template>
  <div class="compare-div">
    <!-- Main title and main description -->
    <div>
      <h1 class="compare-title">{{ $t("compare.title") }}</h1>
      <p class="compare-text">
        {{ $t("compare.description") }}
      </p>
    </div>
    <div id="compare-div-cards" class="compare-div-cards">
      <v-row no-gutters align="center" justify="center">
        <v-col cols="6" class="ma-0 pa-0">
          <!-- City card for city1 -->
          <CompareCard
            v-if="city1 && city2"
            ref="compare-card-1"
            :is-mobile="isMobile"
            :city="city1"
            :city2="city2"
            :data="data"
            @updateCity="changeCityToCompare($event, 1)"
          />
        </v-col>
        <!-- Compare button for desktop version -->
        <v-btn
          v-if="!isMobile"
          large
          class="compare-div-cards-btn"
          rounded
          elevation="0"
          color="#ffd038"
          @click="toggleDisplayStat()"
        >
          <span class="compare-div-cards-btn-text">{{
            $t("compare.compare-btn")
          }}</span>
          <v-icon class="pl-2" color="#314a7e">mdi-chart-box-outline</v-icon>
        </v-btn>
        <!-- Compare button for mobile version -->
        <v-btn
          v-if="isMobile"
          :small="isMobile"
          class="compare-div-cards-btn"
          rounded
          elevation="0"
          color="#ffd038"
          @click="toggleDisplayStat()"
        >
          <span class="compare-div-cards-btn-text">{{
            $t("compare.compare-btn")
          }}</span>
          <v-icon class="pl-2" color="#314a7e">mdi-chart-box-outline</v-icon>
        </v-btn>
        <v-col cols="6" class="ma-0 pa-0 compare-right-col">
          <!-- City card for city2 -->
          <CompareCard
            v-if="city1 && city2"
            ref="compare-card-2"
            :is-mobile="isMobile"
            :city="city2"
            :city2="city1"
            :data="data"
            @updateCity="changeCityToCompare($event, 2)"
          />
        </v-col>
      </v-row>

      <!-- To the next commentary, following rows displays stats -->
      <v-row
        v-if="displayStat"
        id="statistics-first-row"
        no-gutters
        class="compare-div-stats-row"
        justify="center"
        align="center"
      >
        <v-col class="compare-div-stats">
          <p class="compare-div-stats-value compare-div-stats-value-left">
            {{ city1[1].PopTot.toFixed(2) }} M
          </p>
        </v-col>
        <v-col class="compare-div-stats">
          <v-icon x-large color="#13398b">$vuetify.icon.city_population</v-icon>
          <p>{{ $t("compare.statistics.stat1") }}</p>
        </v-col>
        <v-col class="compare-div-stats">
          <p class="compare-div-stats-value compare-div-stats-value-right">
            {{ city2[1].PopTot.toFixed(2) }} M
          </p>
        </v-col>
      </v-row>

      <v-row
        v-if="displayStat"
        no-gutters
        class="compare-div-stats-row"
        justify="center"
        align="center"
      >
        <v-col class="compare-div-stats">
          <p class="compare-div-stats-value compare-div-stats-value-left">
            {{ city1[1].Tot }} km²
          </p>
        </v-col>
        <v-col class="compare-div-stats">
          <v-icon x-large color="#a68e7d"
            >$vuetify.icon.featured_cities_yellow</v-icon
          >
          <p>{{ $t("compare.statistics.stat2") }}</p>
        </v-col>
        <v-col class="compare-div-stats">
          <p class="compare-div-stats-value compare-div-stats-value-right">
            {{ city2[1].Tot }} km²
          </p>
        </v-col>
      </v-row>

      <v-row
        v-if="displayStat"
        no-gutters
        class="compare-div-stats-row"
        justify="center"
        align="center"
      >
        <v-col class="compare-div-stats">
          <p class="compare-div-stats-value compare-div-stats-value-left">
            {{ Math.round(city1[1].PercVeg) }}
            <span style="font-size: 16px">/100</span>
          </p>
        </v-col>
        <v-col class="compare-div-stats">
          <v-icon x-large color="#69b971">$vuetify.icon.klover_score</v-icon>
          <p>{{ $t("compare.statistics.stat3") }}</p>
        </v-col>
        <v-col class="compare-div-stats">
          <p class="compare-div-stats-value compare-div-stats-value-right">
            {{ Math.round(city2[1].PercVeg) }}
            <span style="font-size: 16px">/100</span>
          </p>
        </v-col>
      </v-row>

      <v-row
        v-if="displayStat && city1.weather && city2.weather"
        no-gutters
        class="compare-div-stats-row"
        justify="center"
        align="center"
      >
        <v-col class="compare-div-stats">
          <p class="compare-div-stats-value compare-div-stats-value-left">
            {{
              (city1.weather.meanDeviationYearly.C[
                city1.weather.meanDeviationYearly.C.length - 1
              ] >= 0
                ? "+"
                : "") +
              $n(
                city1.weather.meanDeviationYearly.C[
                  city1.weather.meanDeviationYearly.C.length - 1
                ].toFixed(2)
              ) +
              "°C"
            }}
          </p>
        </v-col>
        <v-col class="compare-div-stats">
          <v-icon x-large color="#b72525">$vuetify.icon.mean_deviation</v-icon>
          <p>
            {{ $t("compare.statistics.stat4") }}
            <span style="color: #67c2ff">{{ currentYear }}</span>
          </p>
        </v-col>
        <v-col class="compare-div-stats">
          <p class="compare-div-stats-value compare-div-stats-value-right">
            {{
              (city2.weather.meanDeviationYearly.C[
                city2.weather.meanDeviationYearly.C.length - 1
              ] >= 0
                ? "+"
                : "") +
              $n(
                city2.weather.meanDeviationYearly.C[
                  city2.weather.meanDeviationYearly.C.length - 1
                ].toFixed(2)
              ) +
              "°C"
            }}
          </p>
        </v-col>
      </v-row>

      <!-- Focus Klover row -->
      <v-row
        v-if="displayStat"
        no-gutters
        class="compare-div-stats-green-row"
        justify="center"
        align="center"
      >
        <v-col class="compare-div-stats">
          <div>
            <v-img
              :src="require('@/assets/images/klover-focus.png')"
              :lazy-src="require('@/assets/images/klover-focus.png')"
              max-height="100px"
              max-width="100px"
              style="margin: auto"
              class="mb-3"
              contain
            />
            <p class="compare-div-stats-green-title">
              {{ $t("compare.focus.title") }}
            </p>
            <p class="compare-div-stats-green-subtitle">
              {{ $t("compare.focus.description") }}
            </p>
          </div>
        </v-col>
      </v-row>

      <v-row
        v-if="displayStat"
        no-gutters
        class="
          compare-div-stats-green-row compare-div-stats-green-row-first-loader
        "
        justify="center"
        align="center"
      >
        <CompareBar
          :value1="city1[1].SurfVeg_m2 / 100"
          :value2="city2[1].SurfVeg_m2 / 100"
          :title="mainBarTitle"
          :is-mobile="isMobile"
        />
      </v-row>

      <v-row
        v-if="displayStat"
        no-gutters
        class="
          compare-div-stats-green-row compare-div-stats-green-row-second-loader
        "
        justify="center"
        align="center"
      >
        <CompareBarOpt :is-mobile="isMobile" :city1="city1" :city2="city2" />
      </v-row>

      <v-row
        v-if="displayStat && city1.weather && city2.weather"
        no-gutters
        class="compare-div-stats-graph-row"
        justify="center"
        align="center"
      >
        <!-- For the documentation of this component, see city.graph.EcartTemperature.vue -->
        <CompareGraph :is-mobile="isMobile" :city1="city1" :city2="city2" />
      </v-row>

      <!-- Redirection row for home -->
      <v-row
        v-if="displayStat"
        no-gutters
        class="compare-div-stats-redirect-row"
        justify="center"
        align="center"
      >
        <v-col cols="6" class="ma-0 pa-0 compare-div-stats-redirect-row">
          <v-container fill-height class="compare-div-stats-redirect-row">
            <div style="margin: auto">
              <h2 class="compare-card-city">
                {{
                  isMobile &&
                  $t("city." + city1[0] + ".name") === "Rio de Janeiro"
                    ? "Rio"
                    : $t("city." + city1[0] + ".name")
                }}
              </h2>
              <p class="compare-card-city-country">
                {{ $t("city." + city1[0] + ".country") }}
              </p>
              <a
                v-if="!isMobile"
                class="compare-div-stats-redirect-btn"
                :href="
                  $i18n.locale === 'fr' ? '/ville/' + city1[0] : '/' + city1[0]
                "
              >
                {{ $t("compare.button") }}
                <v-icon class="ml-2" color="#11388a">mdi-chevron-right</v-icon>
              </a>
              <v-btn
                v-if="isMobile"
                :href="
                  $i18n.locale === 'fr' ? '/ville/' + city1[0] : '/' + city1[0]
                "
                outlined
                rounded
                x-small
                dense
                style="text-transform: inherit"
                color="#11388a"
              >
                {{ $t("compare.button") }}
                <v-icon color="#11388a">mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-container>
        </v-col>
        <v-col
          cols="6"
          class="ma-0 pa-0 compare-div-stats-redirect-row compare-right-col"
        >
          <v-container fill-height class="compare-div-stats-redirect-row">
            <div style="margin: auto">
              <h2 class="compare-card-city">
                {{
                  isMobile &&
                  $t("city." + city2[0] + ".name") === "Rio de Janeiro"
                    ? "Rio"
                    : $t("city." + city2[0] + ".name")
                }}
              </h2>
              <p class="compare-card-city-country">
                {{ $t("city." + city2[0] + ".country") }}
              </p>
              <a
                v-if="!isMobile"
                class="compare-div-stats-redirect-btn"
                :href="
                  $i18n.locale === 'fr' ? '/ville/' + city2[0] : '/' + city2[0]
                "
              >
                {{ $t("compare.button") }}
                <v-icon v-if="!isMobile" class="ml-2" color="#11388a"
                  >mdi-chevron-right</v-icon
                >
              </a>
              <v-btn
                v-if="isMobile"
                :href="
                  $i18n.locale === 'fr' ? '/ville/' + city2[0] : '/' + city2[0]
                "
                outlined
                rounded
                x-small
                dense
                style="text-transform: inherit"
                color="#11388a"
              >
                {{ $t("compare.button") }}
                <v-icon color="#11388a">mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </div>
    <!-- Button to dynamically redirect user to the top page -->
    <UpBtn :custom="'#compare-div-cards'" />
  </div>
</template>

<script>
import CompareCard from "@/components/Compare/CompareCard";
import CompareBar from "@/components/Compare/CompareBar";
import CompareBarOpt from "@/components/Compare/CompareBarOpt";
import CompareGraph from "@/components/Compare/CompareGraph";
import kloverData from "@/assets/klover_data.json";
import UpBtn from "@/components/Page/UpBtn";
import axios from "axios";

export default {
  name: "Compare",
  components: {
    CompareBar,
    CompareBarOpt,
    CompareCard,
    CompareGraph,
    UpBtn,
  },
  metaInfo() {
    var result;
    var resultTitle;
    if (window.location.pathname === "/comparer") {
      resultTitle = "Comparez les villes vertes du monde avec Klover";
      result = [
        {
          name: "title",
          content: "Comparez les villes vertes du monde avec Klover",
        },
        {
          name: "description",
          content:
            "Démographie, végétation, climat : comparez les plus grandes villes du monde grâce aux données satellite",
        },
        { property: "og:type", content: "website" },
        {
          property: "og:url",
          content: "https://klover.city/comparer",
        },
        {
          property: "og:title",
          content: "Comparez les villes vertes du monde avec Klover",
        },
        {
          property: "og:description",
          content:
            "Démographie, végétation, climat : comparez les plus grandes villes du monde grâce aux données satellite",
        },
        { property: "og:image", content: this.$t("meta.home.img") },
        { property: "twitter:card", content: "summary_large_image" },
        {
          property: "twitter:url",
          content: "https://klover.city/comparer",
        },
        {
          property: "twitter:title",
          content: "Comparez les villes vertes du monde avec Klover",
        },
        {
          property: "twitter:description",
          content:
            "Démographie, végétation, climat : comparez les plus grandes villes du monde grâce aux données satellite",
        },
        {
          property: "twitter:image",
          content: "https://klover.city/meta-img/Klover-home-card.jpg",
        },
      ];
    } else {
      resultTitle = "Compare green cities around the world with Klover";
      result = [
        {
          name: "title",
          content: "Compare green cities around the world with Klover",
        },
        {
          name: "description",
          content:
            "Demographics, vegetation, climate : compare big cities around the world using satellite data",
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://klover.city/compare" },
        {
          property: "og:title",
          content: "Compare green cities around the world with Klover",
        },
        {
          property: "og:description",
          content:
            "Demographics, vegetation, climate : compare big cities around the world using satellite data",
        },
        {
          property: "og:image",
          content: "https://klover.city/meta-img/Klover-home-card-en.jpg",
        },
        { property: "twitter:card", content: "summary_large_image" },
        { property: "twitter:url", content: "https://klover.city/compare" },
        {
          property: "twitter:title",
          content: "Compare green cities around the world with Klover",
        },
        {
          property: "twitter:description",
          content:
            "Demographics, vegetation, climate : compare big cities around the world using satellite data",
        },
        {
          property: "twitter:image",
          content: "https://klover.city/meta-img/Klover-home-card-en.jpg",
        },
      ];
    }
    return {
      title: resultTitle,
      meta: result,
    };
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      data: Object.entries(kloverData),
      displayStat: false,
      city1: null,
      city2: null,
      isMobile: false,
    };
  },
  computed: {
    mainBarTitle() {
      return this.$t("compare.focus.area-part");
    },
  },
  mounted() {
    // Initialize with two cities
    this.city1 = this.data[0];
    this.city2 = this.data[1];
    // Get weather data for both cities
    this.getWeatherData();
    this.getWeatherData2();
    // Check if user is on mobile
    this.isMobile = window.innerWidth <= 800 || window.innerHeight <= 600;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth <= 800 || window.innerHeight <= 600;
    });
  },
  methods: {
    // pick random cities for the MoreCities section
    async getWeatherData() {
      let meanDeviationResponse;
      try {
        // Get data from local Nasa Power api for historical weather information for charts
        meanDeviationResponse = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL +
            this.city1[0] +
            "/mean_deviation"
        );
        meanDeviationResponse = meanDeviationResponse.data;
        if (!this.city1.weather) {
          this.city1.weather = {};
        }
        this.city1.weather.meanDeviation = meanDeviationResponse;
        this.$forceUpdate();
      } catch (error) {
        console.error(error);
      }
      let meanDeviationYearlyResponse;

      try {
        // Get data from local Nasa Power api for historical weather information for charts
        meanDeviationYearlyResponse = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL +
            this.city1[0] +
            "/mean_deviation_yearly"
        );
        meanDeviationYearlyResponse = meanDeviationYearlyResponse.data;
        if (!this.city1.weather) {
          this.city1.weather = {};
        }
        this.city1.weather.meanDeviationYearly = meanDeviationYearlyResponse;
        this.$forceUpdate();
      } catch (error) {
        console.error(error);
      }
    },
    // Get weather data for city2
    async getWeatherData2() {
      let meanDeviationResponse;
      try {
        // Get data from local Nasa Power api for historical weather information for charts
        meanDeviationResponse = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL +
            this.city2[0] +
            "/mean_deviation"
        );
        meanDeviationResponse = meanDeviationResponse.data;
        if (!this.city2.weather) {
          this.city2.weather = {};
        }
        this.city2.weather.meanDeviation = meanDeviationResponse;
        this.$forceUpdate();
      } catch (error) {
        console.error(error);
      }
      let meanDeviationYearlyResponse;

      try {
        // Get data from local Nasa Power api for historical weather information for charts
        meanDeviationYearlyResponse = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL +
            this.city2[0] +
            "/mean_deviation_yearly"
        );
        meanDeviationYearlyResponse = meanDeviationYearlyResponse.data;
        if (!this.city2.weather) {
          this.city2.weather = {};
        }
        this.city2.weather.meanDeviationYearly = meanDeviationYearlyResponse;
        this.$forceUpdate();
      } catch (error) {
        console.error(error);
      }
    },
    // Update city to compare
    changeCityToCompare(value, card) {
      this.displayStat = false;
      var self = this;
      this.data.forEach(function (entry) {
        if (value === entry[0]) {
          if (card === 1) {
            self.city1 = entry;
          } else {
            self.city2 = entry;
          }
        }
      });
      this.getWeatherData();
      this.getWeatherData2();
    },
    // Display statistics or not
    toggleDisplayStat() {
      this.displayStat = true;
      this.$nextTick().then(() => {
        this.$vuetify.goTo("#statistics-first-row");
      });
    },
  },
};
</script>

<style scoped>
.compare-right-col {
  border-left: 1px solid #c2c5cb;
}

.compare-card-city {
  font-family: "Albra Bold";
  color: #113689;
  font-size: 32px;
  text-align: center;
  font-display: swap;
}

.compare-div-cards {
  position: relative;
}

.compare-div-cards-btn {
  text-transform: inherit;
  position: absolute;
  z-index: 999;
  top: 275px;
}

.compare-div-cards-btn-text {
  color: #314a7e;
}

.compare-div-stats {
  text-align: center;
}

.compare-div-stats-value {
  font-weight: bold;
  font-size: x-large;
}

.compare-div-stats-row {
  background-color: white;
  min-height: 150px;
  border-bottom: 1px solid #c2c5cb;
}

.compare-div-stats-graph-row {
  background-color: white;
  min-height: 600px;
  border-bottom: 1px solid #c2c5cb;
}

.compare-div-stats-value-left {
  position: relative;
  left: 105px;
}
.compare-div-stats-value-right {
  position: relative;
  right: 118px;
}

.compare-div-stats-green-row {
  background-color: #284996;
  min-height: 350px;
  border-bottom: 1px solid #c2c5cb;
}

.compare-div-stats-green-row-first-loader {
  min-height: 250px;
}

.compare-div-stats-green-row-second-loader {
  min-height: 400px;
}

.compare-div-stats-green-title {
  color: white;
  font-size: 26px;
  font-weight: bold;
}

.compare-div-stats-green-subtitle {
  color: #84aeeb;
}

.compare-div-stats-redirect-row {
  min-height: 300px;
  background-color: white;
}

.compare-div-stats-redirect-btn {
  position: relative;
  top: 24px;
  color: #113689 !important;
  text-decoration: none;
  padding: 15px 20px 15px 20px;
  border: 2px solid #92d3ff;
  border-radius: 30px;
}

.compare-card-city-country {
  text-align: center;
  position: relative;
}

.compare-title {
  font-size: 3vw;
}

@media screen and (max-width: 800px) {
  .compare-title {
    font-size: 24px;
  }

  .compare-text {
    font-size: 16px;
  }

  .compare-div-cards-btn {
    top: 225px;
    z-index: 3;
  }

  .compare-div-stats-value-right {
    right: inherit;
  }

  .compare-div-stats-value-left {
    left: inherit;
  }

  .compare-div-stats-value {
    font-size: 24px;
  }

  .compare-div-stats-graph-row {
    min-height: inherit;
    padding: 3% 3% 3% 3%;
    padding-top: 10%;
    padding-bottom: 10%;
  }

  .compare-card-city {
    font-size: 24px;
  }

  .compare-card-city-country {
    font-size: 14px;
  }

  .compare-div-stats-redirect-row {
    min-height: 175px;
  }
}
</style>

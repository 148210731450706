var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('button',{class:hover && !_vm.isMobile && _vm.active !== 'canopy' ? 'hover-effect' : ''},[(!_vm.isMobile && _vm.active !== 'canopy')?_c('v-icon',{staticClass:"ma-5 compare-bar-opt-btn compare-bar-opt-btn-active",attrs:{"size":"64"},on:{"click":function($event){return _vm.toggleOptions('perHab')}}},[_vm._v("$vuetify.icon.canopy")]):_vm._e(),(!_vm.isMobile && _vm.active === 'canopy')?_c('v-icon',{staticClass:"ma-5 compare-bar-opt-btn compare-bar-opt-btn-active",attrs:{"size":"64"},on:{"click":function($event){return _vm.toggleOptions('perHab')}}},[_vm._v("$vuetify.icon.canopy_active")]):_vm._e(),(_vm.isMobile && _vm.active !== 'canopy')?_c('v-icon',{staticClass:"ma-5 compare-bar-opt-btn compare-bar-opt-btn-active",attrs:{"size":"35px"},on:{"click":function($event){return _vm.toggleOptions('perHab')}}},[_vm._v("$vuetify.icon.canopy")]):_vm._e(),(_vm.isMobile && _vm.active === 'canopy')?_c('v-icon',{staticClass:"ma-5 compare-bar-opt-btn compare-bar-opt-btn-active",attrs:{"size":"35px"},on:{"click":function($event){return _vm.toggleOptions('perHab')}}},[_vm._v("$vuetify.icon.canopy_active")]):_vm._e()],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('button',{class:hover && !_vm.isMobile && _vm.active !== 'walk' ? 'hover-effect' : ''},[(!_vm.isMobile && _vm.active !== 'walk')?_c('v-icon',{staticClass:"ma-5 compare-bar-opt-btn",attrs:{"id":"walk","size":"64"},on:{"click":function($event){return _vm.toggleOptions('walk')}}},[_vm._v("$vuetify.icon.walk")]):_vm._e(),(!_vm.isMobile && _vm.active === 'walk')?_c('v-icon',{staticClass:"ma-5 compare-bar-opt-btn",attrs:{"size":"64"},on:{"click":function($event){return _vm.toggleOptions('walk')}}},[_vm._v("$vuetify.icon.walk_active")]):_vm._e(),(_vm.isMobile && _vm.active !== 'walk')?_c('v-icon',{staticClass:"ma-5 compare-bar-opt-btn",attrs:{"id":"walk","size":"35px"},on:{"click":function($event){return _vm.toggleOptions('walk')}}},[_vm._v("$vuetify.icon.walk")]):_vm._e(),(_vm.isMobile && _vm.active === 'walk')?_c('v-icon',{staticClass:"ma-5 compare-bar-opt-btn",attrs:{"size":"35px"},on:{"click":function($event){return _vm.toggleOptions('walk')}}},[_vm._v("$vuetify.icon.walk_active")]):_vm._e()],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('button',{class:hover && !_vm.isMobile && _vm.active !== 'connectivity'
            ? 'hover-effect'
            : ''},[(!_vm.isMobile && _vm.active !== 'connectivity')?_c('v-icon',{staticClass:"ma-5 compare-bar-opt-btn",attrs:{"id":"space","size":"64"},on:{"click":function($event){return _vm.toggleOptions('space')}}},[_vm._v("$vuetify.icon.connectivity")]):_vm._e(),(!_vm.isMobile && _vm.active === 'connectivity')?_c('v-icon',{staticClass:"ma-5 compare-bar-opt-btn",attrs:{"size":"64"},on:{"click":function($event){return _vm.toggleOptions('space')}}},[_vm._v("$vuetify.icon.connectivity_active")]):_vm._e(),(_vm.isMobile && _vm.active !== 'connectivity')?_c('v-icon',{staticClass:"ma-5 compare-bar-opt-btn",attrs:{"id":"space","size":"35px"},on:{"click":function($event){return _vm.toggleOptions('space')}}},[_vm._v("$vuetify.icon.connectivity")]):_vm._e(),(_vm.isMobile && _vm.active === 'connectivity')?_c('v-icon',{staticClass:"ma-5 compare-bar-opt-btn",attrs:{"size":"35px"},on:{"click":function($event){return _vm.toggleOptions('space')}}},[_vm._v("$vuetify.icon.connectivity_active")]):_vm._e()],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('button',{class:hover && !_vm.isMobile && _vm.active !== 'tree' ? 'hover-effect' : ''},[(!_vm.isMobile && _vm.active !== 'tree')?_c('v-icon',{staticClass:"ma-5 compare-bar-opt-btn",attrs:{"id":"canopy","size":"64"},on:{"click":function($event){return _vm.toggleOptions('tree')}}},[_vm._v("$vuetify.icon.tree")]):_vm._e(),(!_vm.isMobile && _vm.active === 'tree')?_c('v-icon',{staticClass:"ma-5 compare-bar-opt-btn",attrs:{"size":"64"},on:{"click":function($event){return _vm.toggleOptions('tree')}}},[_vm._v("$vuetify.icon.tree_active")]):_vm._e(),(_vm.isMobile && _vm.active !== 'tree')?_c('v-icon',{staticClass:"ma-5 compare-bar-opt-btn",attrs:{"id":"canopy","size":"35px"},on:{"click":function($event){return _vm.toggleOptions('tree')}}},[_vm._v("$vuetify.icon.tree")]):_vm._e(),(_vm.isMobile && _vm.active === 'tree')?_c('v-icon',{staticClass:"ma-5 compare-bar-opt-btn",attrs:{"size":"35px"},on:{"click":function($event){return _vm.toggleOptions('tree')}}},[_vm._v("$vuetify.icon.tree_active")]):_vm._e()],1)]}}])})],1),_c('v-row',{staticClass:"mt-3 pt-3"},[(_vm.init)?_c('CompareBar',{attrs:{"walk":_vm.walk,"progress-value":_vm.progressValue,"unit-global":_vm.unitGlobal,"title":_vm.label,"value1":_vm.value1,"value2":_vm.value2,"unit1":_vm.unit1,"unit2":_vm.unit2,"is-mobile":_vm.isMobile}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card class="compare-card" elevation="0">
    <v-img
      :lazy-src="require('@/assets/images/' + city[0] + '.jpg')"
      :src="require('@/assets/images/' + city[0] + '.jpg')"
      class="compare-card-img"
    >
    </v-img>
    <div class="compare-card-second-div">
      <v-container fill-height>
        <v-row justify="center" align="center">
          <h2 class="compare-card-city">
            {{
              isMobile && $t("city." + city[0] + ".name") === "Rio de Janeiro"
                ? "Rio"
                : $t("city." + city[0] + ".name")
            }}
          </h2>

          <v-menu :top="isMobile" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <button
                class="compare-card-city-list-btn"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon v-if="!isMobile" color="#113689"
                  >mdi-chevron-down-circle-outline</v-icon
                >
                <v-icon v-if="isMobile" size="16px" color="#113689"
                  >mdi-chevron-down-circle-outline</v-icon
                >
              </button>
            </template>
            <v-list class="overflow-y-auto" style="max-height: 200px">
              <v-list-item
                v-for="(d, index) in data"
                :key="index"
                :inactive="d[0] !== city[0] && d[0] !== city2[0] ? false : true"
                :ripple="d[0] !== city[0] && d[0] !== city2[0] ? true : false"
                @click="updateCity(d[0])"
              >
                <v-list-item-title
                  v-if="d[0] !== city[0] && d[0] !== city2[0]"
                  >{{ $t("city." + d[0] + ".name") }}</v-list-item-title
                >
                <v-list-item-title v-if="d[0] === city[0] || d[0] === city2[0]"
                  ><span style="color: #e8e8e8">{{
                    $t("city." + d[0] + ".name")
                  }}</span></v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
        <v-row justify="center" class="compare-card-country-row">
          <p class="compare-card-country">
            {{ $t("city." + city[0] + ".country") }}
          </p>
        </v-row>
      </v-container>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "CompareCard",
  props: {
    city: {
      required: true,
      type: Array,
    },
    city2: {
      required: true,
      type: Array,
    },
    data: {
      required: true,
      type: Array,
    },
    isMobile: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateCity(newVal) {
      if (newVal !== this.city[0] && newVal !== this.city2[0]) {
        this.$emit("updateCity", newVal);
      }
    },
  },
};
</script>

<style scoped>
.compare-card {
  border-radius: 0;
  border-bottom: 1px solid #c2c5cb;
  height: 400px;
  max-width: 100%;
}

.compare-card-city {
  font-family: "Albra Bold";
  color: #113689;
  font-size: 32px;
  font-display: swap;
}

.compare-card-city-list-btn {
  padding-left: 1%;
}

.compare-card-country {
  color: #264795;
}

.compare-card-country-row {
  position: relative;
  top: -80px;
  left: -10px;
}

.compare-card-img {
  height: 225px;
}

.compare-card-second-div {
  height: 225px;
}

@media screen and (max-width: 800px) {
  .compare-card {
    height: 275px;
  }

  .compare-card-img {
    height: 125px;
  }

  .compare-card-second-div {
    height: 200px;
  }

  .compare-card-city {
    font-size: 24px;
  }

  .compare-card-country {
    font-size: 14px;
  }

  .compare-card-country-row {
    left: inherit;
  }
}
</style>
